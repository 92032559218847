import gql from 'graphql-tag';

export interface SuperAdminLogoutResponse {
  logoutSuperAdmin: {
    success: boolean;
  };
}

export const LogoutSuperAdminQuery = gql`
  query SuperAdminLogout {
    logoutSuperAdmin {
      success
    }
  }`;